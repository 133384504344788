import React, { Suspense } from 'react'
import styled from 'styled-components';

const Animation = React.lazy(() => import('./Animation'));

import './App.css';

const Cover = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Consolas', monospace;
  font-weight: 200;
  text-align: center;
`;

const Heading = styled.h1`
  color: rgba(140, 255, 170, 0.8);
  max-width: 80%;
`;

function App() {
  return (
    <>
      <Cover>
        <Heading>Follow the white rabbit...</Heading>
      </Cover>
      <Suspense fallback={<div className='fallback'></div>}>
        <Animation />
      </Suspense>
    </>
  )
}

export default App
